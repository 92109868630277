// Helpers
import { dang } from "@/helpers/consoleColors";

// Types
import type { I_apiImageConvert_ReturnData } from "@/hooks/requests/apiImageConvert/apiImageConvert.types";

// Hooks
import apiImageConvert from "@/hooks/requests/apiImageConvert/apiImageConvert";
import apiGetImageConverted from "@/hooks/requests/apiGetImageConverted/apiGetImageConverted";

// const REGEX_IMAGE_FORMAT_HEIC: RegExp = /\.heic$/i;
const REGEX_IMAGE_ALL_FORMATS: RegExp = /\.(png|jpeg|jpg|bmp)$/i;

export const isNeedConvertImageToJpeg = (target: HTMLInputElement): boolean => {
    if (target.files.length === 0) {
        return false;
    }

    const filename = target.files[0].name;

    return !REGEX_IMAGE_ALL_FORMATS.test(filename);
};

export const convertImageToJpeg = async (target: HTMLInputElement): Promise<void> => {
    if (target.files.length === 0) {
        return;
    }

    try {
        const targetFile = target.files[0];

        const formData: FormData = new FormData();
        formData.set("image", targetFile);

        const response: I_apiImageConvert_ReturnData = await apiImageConvert(formData);
        const blob: Blob = await apiGetImageConverted(response.link);

        const filename: string = targetFile.name.replace(/(\.\w*|)$/i, ".jpg")
        const file: File = new File([blob], filename, {
            type: "image/jpeg",
        });

        const dataTransfer: DataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        target.files = dataTransfer.files;
    } catch (e) {
        console.log("%c[convertImageToJpeg - catch]", dang, e);
    }
};
