// Data
import { I_AXIOS } from "@/hooks/requests/configRequest.model";

// Hooks
import it4GetUploadId from "@/hooks/requests/it4GetUploadId/it4GetUploadId";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { useDownloadPhotosStore } from "@/stores/downloadPhotos/downloadPhotos";
import { useEvaluationResultStore } from "@/stores/evaluationResult/evaluationResult";
import { info } from "@/helpers/consoleColors";
import { BugTrackerSection, useBugTracker } from "@/helpers/bugTracker";

/** Сгенерировать путь для QR-кода */
export default async function useQRPath(): Promise<void> {
    const QRPath = new URL(I_AXIOS.IT4_HOST_PREVIEW + I_AXIOS.IT4_QR_ENDPOINT);

    const TIWStore = useTIWStore();
    const HowYouRateDeviceStore = useHowYouRateDeviceStore();
    const DownloadPhotosStore = useDownloadPhotosStore();
    const EvaluationResultStore = useEvaluationResultStore();

    try {
        const uploadIdData = await it4GetUploadId({
            token: TIWStore.WIDGET_DATA.token,
            deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
        });

        useBugTracker("useQRPath - it4GetUploadId (response)", BugTrackerSection.INFO, {
            response: uploadIdData,
            standardizedLocale: TIWStore.standardizedLocale,
        })

        QRPath.searchParams.set("token", uploadIdData.uploadId);
        QRPath.searchParams.set(
            "lang",
            TIWStore.standardizedLocale
        );

        DownloadPhotosStore.qrPath =
            I_AXIOS.QR_API_IT4 + encodeURIComponent(QRPath.toString());
        EvaluationResultStore.qrPath = DownloadPhotosStore.qrPath;

        console.log("%c[TIWidget] -> [QR-link]", info, QRPath.toString());
    } catch (e) {
        useBugTracker("useQRPath - it4GetUploadId", BugTrackerSection.ERROR, {
            exception: e.message,
            request: {
                token: TIWStore.WIDGET_DATA.token,
                deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
            },
        })
    }
}
